// This is a partial reimplementation of Material-UI's <CircularProgress />

import React from 'react'
import styled, { keyframes } from 'styled-components'

const SVG_SIZE = 44;

const Spinner = (props) => {
    const {
        className,
        size = 40,
        thickness = 3.6,
    } = props;

    return (
        <span className={className} role="progressbar" style={{ width: size, height: size }}>
            <svg viewBox={`${SVG_SIZE / 2} ${SVG_SIZE / 2} ${SVG_SIZE} ${SVG_SIZE}`}>
                <circle cx={SVG_SIZE} cy={SVG_SIZE} r={(SVG_SIZE - thickness) / 2} fill="none" strokeWidth={thickness} />
            </svg>
        </span>
    )
};

const circularDashAnimation = keyframes`
    0% {
        stroke-dasharray: 1px, 200px;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -15px;
    }

    100% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -125px;
    }
`;

const StyledSpinner: React.FC = styled(Spinner)`
    animation: ${circularDashAnimation} 1.4s linear infinite;
    color: ${({ color }) => color ?? 'inherit'};

    display: inline-block;

    svg {
        display: block;
    }

    circle {
        stroke: currentColor;
    }
`;

export default StyledSpinner;
